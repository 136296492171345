import { RootStateOrAny } from 'react-redux';
import { handleActions } from 'redux-actions';
import { getFeaturedArtists, getArtistProfile } from './artists.actions';

export default handleActions(
  {
    [getFeaturedArtists().type]: (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      featuredArtists: action.payload,
    }),
    'artworks/GET_ARTIST_PROFILE': (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({ ...state, featuredProfile: action.payload }),
  },
  { featuredArtists: [], featuredProfile: {} },
);
