import { createStore, applyMiddleware, combineReducers } from 'redux';
import promiseMiddleware from 'redux-promise';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import artworksReducer from './artworks/artworks.reducer';
import lotsReducer from './lots/lots.reducer';
import artistsReducer from './artists/artists.reducer';
import auctionReducer from './auction/auction.reducer';
import profileReducer from './profile/profile.reducer';
import notificationReducer from './notifications/notifications.reducer';

const rootReducer = combineReducers({
  artworks: artworksReducer,
  lots: lotsReducer,
  artists: artistsReducer,
  auction: auctionReducer,
  profile: profileReducer,
  notifications: notificationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  applyMiddleware(logger, thunk, promiseMiddleware),
);

export default store;
