import { RootStateOrAny } from 'react-redux';
import { handleActions } from 'redux-actions';
import { getFeaturedArtworks } from './artworks.actions';

export default handleActions(
  {
    [getFeaturedArtworks().type]: (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      featuredArtworks: action.payload,
    }),
  },
  { featuredArtworks: [] },
);
