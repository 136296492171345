import mockData from '@utils/mock.json';
import request, { HttpVerb } from './request';

export const getFeaturedArtists = async () =>
  new Promise((resolve) =>
    setTimeout(() => resolve(mockData['featured-artists']), 100),
  );
// return request(HttpVerb.GET, '/artists/featured');

export const getArtistProfile = async () =>
  new Promise((resolve) =>
    setTimeout(() => resolve(mockData['profile-artist']), 100),
  );
