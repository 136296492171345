import React, { ReactElement, useEffect, useState } from 'react';

import ContentCentered from '@components/Content/ContentCentered';
import Dropdown from '@components/Dropdowns';
import Arrow from '@components/Arrow';
import ItemDisplay from '@components/ItemDisplay';
import LotDisplay from '@components/LotDisplay';
import withCountdown from '@components/withCountdown';
import withFavorites from '@components/withFavorites';
import { AuctionItemsEnum, ILotStruct } from '@shared/types';

import useWindowDimensions from '@shared/hooks/useWindowDimensions';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearAuctionItems,
  getAuctionItems,
} from '@redux/auction/auction.actions';
import { RootState } from '@redux/store';
import { generateShortUUID, NOOP_FUNCTION } from '@utils/index';
import styles from './Auction.module.scss';

const sectionAnchor = 'gallery-container';
const dropDownOptions = [
  { id: 0, title: 'Recently added', value: AuctionItemsEnum.RECENTLY_ADDED },
  { id: 1, title: 'Active auction', value: AuctionItemsEnum.ACTIVE },
  { id: 2, title: 'Highest price', value: AuctionItemsEnum.HIGHEST_PRICE },
  { id: 3, title: 'Lowest price', value: AuctionItemsEnum.LOWEST_PRICE },
];

interface IFilterData {
  page: number;
  filter: AuctionItemsEnum;
}

const withFavoritesAndCountdown = (
  wrapperComponent: ReactElement,
  startTime: number,
  endTime: number,
  onClick: () => void = NOOP_FUNCTION,
) =>
  withFavorites(withCountdown(wrapperComponent, startTime, endTime), onClick);

const Overview = () => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState<IFilterData>({
    page: 1,
    filter: AuctionItemsEnum.RECENTLY_ADDED,
  });

  const { width, breakpoints } = useWindowDimensions();

  const smallSize = () => width <= breakpoints.md;

  useEffect(() => {
    dispatch(getAuctionItems(filterData.page, filterData.filter));
  }, [filterData]);

  const auctionItems = useSelector(
    (state: RootState) => state.auction.auctionItems,
  );

  const handleOptionSelect = (_, { value }) => {
    if (value === filterData.filter) {
      return;
    }

    dispatch(clearAuctionItems());
    setFilterData({ page: 1, filter: value });
  };

  return (
    <div id={sectionAnchor}>
      <ContentCentered>
        <div className={styles.dropdown}>
          <Dropdown options={dropDownOptions} onChange={handleOptionSelect} />
        </div>
      </ContentCentered>
      <ContentCentered>
        <div className={styles.auctionItem}>
          {auctionItems.map((itemData) => {
            if (!itemData.metadata) return null;
            return withFavoritesAndCountdown(
              <ItemDisplay itemInfo={itemData} key={itemData.id} />,
              itemData.auctionStartTime,
              itemData.auctionEndTime,
            );
          })}
        </div>
      </ContentCentered>
      <div
        className={styles.viewMore}
        onClick={() => {
          setFilterData({ ...filterData, page: filterData.page + 1 });
        }}
      >
        View more&nbsp;
        <Arrow down />
      </div>
      {!smallSize() && (
        <ContentCentered>
          <a className={styles.backToTop} href={`#${sectionAnchor}`}>
            Back to top&nbsp;
            <Arrow up />
          </a>
        </ContentCentered>
      )}
    </div>
  );
};

export default Overview;
