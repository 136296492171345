import { createAction } from 'redux-actions';
import { LotsAPI } from '@api';

export const getFeaturedLot = createAction(
  'artworks/GET_FEATURED_LOT',
  async () => {
    const response = await LotsAPI.getFeaturedLot();
    return response;
  },
);
