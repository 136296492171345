import mockData from '@utils/mock.json';
import { mod } from '@utils/index';

export const getFeaturedProfile = async () =>
  new Promise((resolve) =>
    setTimeout(() => resolve(mockData['featured-personal-profile']), 100),
  );
// return request(HttpVerb.GET, '/person-profile');

export const getMainProfileInfo = async () =>
  new Promise((resolve) =>
    setTimeout(() => resolve(mockData['featured-personal-profile']), 100),
  );
// return request(HttpVerb.GET, '/person-profile/me');

export const getProfileBidHistory = async () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(mockData['bid-history']), 100);
  });

// return request(HttpVerb.GET, '/person-profile/bid-history');

export const getProfileCollection = async (page: number) => {
  const itemsByPages: any = mockData['profile-collection'];
  const items = itemsByPages[mod(page, itemsByPages.length)];
  return new Promise((resolve) =>
    setTimeout(() => resolve({ items, pageLength: itemsByPages.length }), 100),
  );
  // request(HttpVerb.GET, `/person-profile/collection?page=${page}`)
};

export const getProfileFavorites = async (page: number) => {
  const itemsByPages: any = mockData['profile-favorites'];
  const items = itemsByPages[mod(page, itemsByPages.length)];
  return new Promise((resolve) =>
    setTimeout(() => resolve({ items, pageLength: itemsByPages.length }), 100),
  );
  // request(HttpVerb.GET, `/person-profile/favorites?page=${page}`)
};

export const postProfileInformation = async (payload) =>
  new Promise((resolve) =>
    setTimeout(() => resolve(console.log(payload)), 100),
  );
// return request(HttpVerb.POST, '/person-profile');

export const postTransactionBasedNotifications = async (payload) =>
  new Promise((resolve) =>
    setTimeout(() => resolve(console.log(payload)), 100),
  );
// return request(HttpVerb.POST, '/person-profile/transaction-based-notifications');

export const postNewFollowerNotification = async (payload) =>
  new Promise((resolve) =>
    setTimeout(() => resolve(console.log(payload)), 100),
  );
// return request(HttpVerb.POST, '/person-profile/new-follower-notifications');

export const postNewArtworkNotifications = async (payload) =>
  new Promise((resolve) =>
    setTimeout(() => resolve(console.log(payload)), 100),
  );
// return request(HttpVerb.POST, '/person-profile/new-artwork-notifications');
