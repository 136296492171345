import { createAction } from 'redux-actions';
import { ArtistsAPI } from '@api';

export const getFeaturedArtists = createAction(
  'artworks/GET_FEATURED_ARTISTS',
  async () => {
    const response = await ArtistsAPI.getFeaturedArtists();
    return response;
  },
);

export const getArtistProfile = createAction(
  'artworks/GET_ARTIST_PROFILE',
  async (id: number) => {
    const artists: any = await ArtistsAPI.getFeaturedArtists();
    // eslint-disable-next-line eqeqeq
    const response = await artists.find((obj) => obj.id == id);
    return response;
  },
);
