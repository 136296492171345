import { RootStateOrAny } from 'react-redux';
import { handleActions } from 'redux-actions';
import { getFeaturedLot } from './lots.actions';

export default handleActions(
  {
    [getFeaturedLot().type]: (state: RootStateOrAny = {}, action: Action) => ({
      ...state,
      featuredLot: action.payload,
    }),
  },
  { featuredLot: [] },
);
