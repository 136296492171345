import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import Web3ReactManager from '@components/Web3ReactManager';
import NotificationManager from '@components/NotificationAlertManager';

import Footer from '@components/Footer';
import Landing from '@pages/Landing';
import NavbarEmpty from '@components/NavBars/NavbarEmpty';
import Platform from '@pages/Platform';
import styles from './App.module.scss';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <div className={styles.appContainer}>
      <Router forceRefresh>
        <ScrollToTop />
        {/* <NotificationManager /> */}
        <Switch>
          <Route path="/platform">
            <Platform />
          </Route>
          <Route path="/">
            <NavbarEmpty />
            <Route path="/" component={Landing} />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
