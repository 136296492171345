import React from 'react';
import classNames from '@utils/classNames';
import globalStyles from '@styles/_globals.scss';
import { ColorStyles } from '@shared/types';

export interface IBulletPoint {
  variant: ColorStyles;
}

const BulletPoint = ({ variant }: IBulletPoint) => (
  <span
    className={classNames({
      [globalStyles.colorPrimary]: variant === ColorStyles.PRIMARY,
      [globalStyles.colorSecondary]: variant === ColorStyles.SECONDARY,
      [globalStyles.colorLightGray]: variant === ColorStyles.LIGHT_GRAY,
      [globalStyles.colorSoftGreen]: variant === ColorStyles.SOFT_GREEN,
      [globalStyles.colorCaution]: variant === ColorStyles.CAUTION,
      [globalStyles.colorAttention]: variant === ColorStyles.ATTENTION,
      [globalStyles.colorInformative]: variant === ColorStyles.INFORMATIVE,
      [globalStyles.colorSoftBlue]: variant === ColorStyles.STANDBY,
    })}
  >
    ●
  </span>
);

export default BulletPoint;
