import { RootStateOrAny } from 'react-redux';
import { handleActions } from 'redux-actions';
import {
  getFeaturedProfile,
  getMainProfileInfo,
  getBidHistory,
} from './profile.actions';

export default handleActions(
  {
    [getFeaturedProfile().type]: (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      featuredProfile: action.payload,
    }),
    [getMainProfileInfo().type]: (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      mainProfileInformation: action.payload,
    }),
    [getBidHistory().type]: (state: RootStateOrAny = {}, action: Action) => ({
      ...state,
      bidHistory: action.payload,
    }),
    'profile/GET_PROFILE_COLLECTION': (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      profileCollection: (state.profileCollection || []).concat(
        action.payload.items,
      ),
      profileCollectionPages: action.payload.pageLength,
    }),
    'profile/GET_PROFILE_FAVORITES': (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      profileFavorites: (state.profileFavorites || []).concat(
        action.payload.items,
      ),
      profileFavoritesPages: action.payload.pageLength,
    }),
    'profile/SET_USER_LOGGED_IN': (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      loggedIn: action.payload,
    }),
  },
  {
    loggedIn: false,
    featuredProfile: {},
    profileCollectionPages: 0,
    profileFavoritesPages: 0,
    mainProfileInformation: {},
    bidHistory: [],
    profileCollection: [],
    profileFavorites: [],
  },
);
