import { createAction } from 'redux-actions';
import { ArtworksAPI } from '@api';

export const getFeaturedArtworks = createAction(
  'artworks/GET_FEATURED_ARTWORKS',
  async () => {
    const response = await ArtworksAPI.getFeaturedArtworks();
    return response;
  },
);
