import { RootStateOrAny } from 'react-redux';
import { handleActions, Action } from 'redux-actions';

export default handleActions(
  {
    'auction/GET_AUCTION_ITEMS': (
      state: RootStateOrAny = {},
      action: Action<any>,
    ) => ({
      ...state,
      auctionItems: (state.auctionItems || []).concat(action.payload),
    }),
    'auction/CLEAR_AUCTION_ITEMS': (
      state: RootStateOrAny = {},
      action: Action<any>,
    ) => ({
      ...state,
      auctionItems: [],
    }),
    'auction/GET_SINGLE_AUCTION_ITEM': (
      state: RootStateOrAny = {},
      action: Action<any>,
    ) => ({
      ...state,
      showcaseItem: {
        ...state.showcaseItem,
        details: action.payload,
      },
    }),
    'auction/GET_SIMILAR_AUCTION_ITEMS': (
      state: RootStateOrAny = {},
      action: Action<any>,
    ) => ({
      ...state,
      showcaseItem: {
        ...state.showcaseItem,
        similar: action.payload,
      },
    }),
  },
  { auctionItems: [], showcaseItem: { details: null, similar: [] } },
);
