export enum NotificationTypeEnum {
  WON = 'won',
  SETTLE = 'settle',
  OUTBID = 'outbid',
}

export enum AuctionItemsEnum {
  RECENTLY_ADDED = 'recently-added',
  ACTIVE = 'active',
  HIGHEST_PRICE = 'highest-price',
  LOWEST_PRICE = 'lowest-price',
}

export enum ColorStyles {
  PRIMARY,
  SECONDARY,
  LIGHT_GRAY,
  SOFT_GREEN,
  CAUTION,
  ATTENTION,
  INFORMATIVE,
  STANDBY,
}

export interface ILotStruct {
  id?: number;
  name: string;
  type: string;
  priceInEth: number;
  priceInWei: number;
  artworks: PaintingPreviewInfo[];
}

export interface Artworks {
  name: string;
  code: string;
  author: string;
  id: number;
  status: string;
  dateAdded: number;
  priceInEth: number;
  priceInWei: number;
  metadata: {
    url: string;
    description: string;
  };
}
export interface IBidItem {
  type: string;
  name: string;
  code: string;
  artworks?: Artworks[];
  author: string;
  id: number;
  status: string;
  dateAdded: number;
  priceInEth: number;
  priceInWei: number;
  auctionStartTime: number;
  auctionEndTime: number;
  metadata: {
    url: string;
    description: string;
  };
}
export interface IBidStruct {
  bidItem: IBidItem;
  userBidState: string;
  auctionState: string;
  ownerAddr: string;
  currentBid: {
    priceInEth: number;
    priceInWei: number;
  };
  userBid: {
    priceInEth: number;
    priceInWei: number;
  };
}

export enum AuctionStateEnum {
  AVAILABLE_TO_RESERVE = 'available',
  IN_PROGRESS = 'active',
  SOLD = 'sold',
  INCOMING_BID = 'incoming_bid',
}

export type PaintingMetadata = {
  description: string;
  url: string;
};

export type PaintingPreviewInfo = {
  id?: number;
  author: string;
  name: string;
  priceInEth: number;
  code: string;
  auctionStartTime: number;
  auctionEndTime: number;
  status: AuctionStateEnum;
  metadata: PaintingMetadata;
  type: string;
};
