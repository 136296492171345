import { RootStateOrAny } from 'react-redux';
import { handleActions } from 'redux-actions';
import { groupDates } from '@shared/hooks/notifications';
import { getNotifications, addNotification } from './notifications.actions';

export default handleActions(
  {
    [getNotifications().type]: (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      notifications: groupDates(action.payload),
    }),
    'artworks/ADD_NOTIFICATION': (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      notifications: [...state.notifications, action.payload],
    }),
    'artworks/REMOVE_NOTIFICATION': (
      state: RootStateOrAny = {},
      action: Action,
    ) => ({
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.id !== action.payload.id,
      ),
    }),
  },
  { notifications: [] },
);
