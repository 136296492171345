import { createAction } from 'redux-actions';
import { ProfileAPI } from '@api';

export const getFeaturedProfile = createAction(
  'profile/GET_FEATURED_PROFILE',
  async () => {
    const response = await ProfileAPI.getFeaturedProfile();
    return response;
  },
);

export const getMainProfileInfo = createAction(
  'profile/GET_MAIN_PROFILE_INFO',
  async () => {
    const response = await ProfileAPI.getMainProfileInfo();
    return response;
  },
);

export const getBidHistory = createAction(
  'profile/GET_PROFILE_BID_HISTORY',
  async () => {
    const response = await ProfileAPI.getProfileBidHistory();
    return response;
  },
);

export const getProfileCollection = createAction(
  'profile/GET_PROFILE_COLLECTION',
  async (pageNumber: number) => {
    const response = await ProfileAPI.getProfileCollection(pageNumber);
    return response;
  },
);

export const getProfileFavorites = createAction(
  'profile/GET_PROFILE_FAVORITES',
  async (pageNumber: number) => {
    const response = await ProfileAPI.getProfileFavorites(pageNumber);
    return response;
  },
);

export const postProfileInformation = createAction(
  'profile/POST_PROFILE_INFORMATION',
  async (payload) => {
    const response = await ProfileAPI.postProfileInformation(payload);
    return response;
  },
);

export const postTransactionBasedNotifications = createAction(
  'profile/POST_TRANSACTION_BASED_NOTIFICATION',
  async (payload) => {
    const response = await ProfileAPI.postTransactionBasedNotifications(
      payload,
    );
    return response;
  },
);

export const postNewFollowerNotification = createAction(
  'profile/POST_NEW_FOLLOWER_NOTIFICATION',
  async (payload) => {
    const response = await ProfileAPI.postTransactionBasedNotifications(
      payload,
    );
    return response;
  },
);

export const postNewArtworkNotifications = createAction(
  'profile/POST_NEW_ARTWORK_NOTIFICATION',
  async (payload) => {
    const response = await ProfileAPI.postNewArtworkNotifications(payload);
    return response;
  },
);

export const getFeaturedBidHistory = createAction(
  'profile/GET_FEATURED_BID_HISTORY',
  async () => {
    const response = await ProfileAPI.getMainProfileInfo();
    return response;
  },
);

export const setUserLoggedIn = createAction('profile/SET_USER_LOGGED_IN');
