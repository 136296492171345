import { createAction } from 'redux-actions';
import { NotificationAPI } from '@api';

export const getNotifications = createAction(
  'artworks/GET_NOTIFICATIONS',
  async () => {
    const response = await NotificationAPI.getNotifications();
    return response;
  },
);

export const addNotification = createAction(
  'artworks/ADD_NOTIFICATION',
  async (notification: any) => notification,
);

export const removeNotification = createAction(
  'artworks/REMOVE_NOTIFICATION',
  async (notification: any) => notification,
);
