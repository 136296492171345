import React from 'react';

import BulletedText from '@components/BulletedText';
import { ColorStyles } from '@shared/types';

import styles from './NavbarEmpty.module.scss';

const NavbarEmpty: React.FC = () => (
  <div className={styles.navbarContainer}>
    <div className={styles.navbarContent}>
      <BulletedText variant={ColorStyles.SOFT_GREEN}>
        <span className={styles.logoText}>The Digital</span>
      </BulletedText>
    </div>
  </div>
);

export default NavbarEmpty;
