import React from 'react';

interface IArrowProps {
  up?: boolean;
  down?: boolean;
  left?: boolean;
  right?: boolean;
  leftUp?: boolean;
  rightUp?: boolean;
}

const Arrow: React.FC<IArrowProps> = (props) => {
  const {
    up = false,
    down = false,
    left = false,
    right = false,
    leftUp = false,
    rightUp = false,
  } = props;
  let arrowText = '↑';

  if (up) {
    arrowText = '↑';
  }
  if (down) {
    arrowText = '↓';
  }
  if (left) {
    arrowText = '←';
  }
  if (right) {
    arrowText = '→';
  }
  if (leftUp) {
    arrowText = '↖';
  }
  if (rightUp) {
    arrowText = '↗';
  }

  return <span>{`${arrowText} `}</span>;
};

export default Arrow;
