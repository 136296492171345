import mockData from '@utils/mock.json';
import { mod } from '@utils/index';
import { AuctionItemsEnum } from '@shared/types';
import request, { HttpVerb } from './request';

function randomNum(min, max, currId) {
  const arr: any = [];
  while (arr.length < 3) {
    const r = Math.floor(Math.random() * max) + min;
    if (arr.indexOf(r) === -1 && r !== currId) arr.push(r);
  }

  return arr;
}

export const getAuctionItems = async (
  page: number = 1,
  filter: AuctionItemsEnum = AuctionItemsEnum.RECENTLY_ADDED,
) => {
  const items: any = mockData['auction-item-view'];

  return new Promise((resolve) => setTimeout(() => resolve(items), 100));
  // mocked data
  // request(HttpVerb.GET, `/auction/items?page=${page}&filter=${filter}`)
};

export const getSingleAuctionItem = async (id: string) => {
  const mockedItemIndex = parseInt(id, 10);
  return new Promise((resolve) =>
    setTimeout(() => resolve(mockData['auction-item-view'][mockedItemIndex])),
  );

  // request(HttpVerb.GET, `/auction/items/${id}`)
};

export const getSimilarAuctionItems = async (currentId: string) => {
  const mockedItemsIds = randomNum(
    0,
    mockData['auction-item-view'].length,
    currentId,
  );
  const mockedItems = mockedItemsIds.map(
    (id: number) => mockData['auction-item-view'][id],
  );

  return new Promise((resolve) => setTimeout(() => resolve(mockedItems), 100));
};
