export enum Endpoints {
  home = '/platform',
  about = '/platform/about',
  discover = '/platform/discover',
  auction = '/platform/auction',
  profile = '/platform/personal-profile',
  artwork = '/platform/artwork',
  help = '/platform/help',
  TOS = '/platform/TOS',
  privacy = '/platform/privacy',
  artists = '/platform/artists',
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const TIME_FORMATS = [
  [60, 'seconds', 1],
  [120, '1 minute ago', '1 minute from now'],
  [3600, 'minutes', 60],
  [7200, '1 hour ago', '1 hour from now'],
  [86400, 'hours', 3600],
  [172800, 'Yesterday', 'Tomorrow'],
  [604800, 'days', 86400],
  [1209600, 'Last week', 'Next week'],
  [2419200, 'weeks', 604800],
  [4838400, 'Last month', 'Next month'],
  [29030400, 'months', 2419200],
  [58060800, 'Last year', 'Next year'],
  [2903040000, 'years', 29030400],
  [5806080000, 'Last century', 'Next century'],
  [58060800000, 'centuries', 2903040000],
];
