import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Navbar from '@components/NavBars';
import NotificationManager from '@components/NotificationAlertManager';
import About from '@pages/About';
import Home from '@pages/Home';
import Discover from '@pages/Discover';
import Auction from '@pages/Auction';
import Artwork from '@pages/Artwork';
import Profile from '@pages/Profile';
import Help from '@pages/Help';
import ArtistProfile from '@pages/ArtistProfile';
import TOS from '@pages/TOS';
import Privacy from '@pages/Privacy';

const Platform = () => {
  const match = useRouteMatch();

  return <div />;
  return (
    <>
      <NotificationManager />
      <Navbar />
      <Switch>
        <Route path={`${match.path}/auction`} component={Auction} />
        <Route path={`${match.path}/discover`} component={Discover} />
        <Route path={`${match.path}/personal-profile`} component={Profile} />
        <Route path={`${match.path}/about`} component={About} />
        <Route path={`${match.path}/artwork/:id`} component={Artwork} />
        <Route path={`${match.path}/help`} component={Help} />
        <Route path={`${match.path}/artists/:id`} component={ArtistProfile} />
        <Route path={`${match.path}/TOS`} component={TOS} />
        <Route path={`${match.path}/privacy`} component={Privacy} />
        <Route path={`${match.path}/discover`} component={Discover} />
        <Route path={`${match.path}/`} component={Home} />
      </Switch>
    </>
  );
};

export default Platform;
