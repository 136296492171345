/* eslint-disable no-useless-escape */

export function generateShortUUID() {
  const firstPart = Math.random() * 46656;
  const secondPart = Math.random() * 46656;
  return (
    `000${firstPart.toString(36)}`.slice(-3) +
    `000${secondPart.toString(36)}`.slice(-3)
  );
}

export function getPageHeight() {
  const { body } = document;
  const html = document.documentElement;

  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );

  return height;
}

export const timeInSeconds = () => new Date().getTime() / 1000;

export function mod(a: number, b: number): number {
  return ((a % b) + b) % b;
}

export function getWindow(): any {
  if (typeof window !== 'undefined') {
    return window;
  }
  return null;
}

export function getDocument(): any {
  if (typeof document !== 'undefined') {
    return document;
  }
  return null;
}

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const NOOP_FUNCTION = () => {};
