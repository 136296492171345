import React, { PropsWithChildren } from 'react';
import BulletPoint from '@components/BulletPoint';
import { ColorStyles } from '@shared/types';
import styles from './BulletedText.module.scss';

interface IBulletedTextProps {
  variant: ColorStyles;
}

const BulletedText = ({
  variant = ColorStyles.PRIMARY,
  children,
}: PropsWithChildren<IBulletedTextProps>) => (
  <div>
    <span style={{ marginRight: '5px' }}>
      <BulletPoint variant={variant} />
    </span>
    <span>{children}</span>
  </div>
);

export default BulletedText;
