// TODO: @branch take this value from an .env file
const API_URL = 'https://61b498fc-f995-4a27-ba29-545127eafff9.mock.pstmn.io';

export enum HttpVerb {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function getFullEndpoint(endpoint: string) {
  let formattedEndpoint = endpoint;
  if (endpoint[0] !== '/') {
    formattedEndpoint = `/${endpoint[0]}`;
  }
  if (endpoint[endpoint.length - 1] !== '/') {
    formattedEndpoint += '/';
  }

  return `${API_URL}${formattedEndpoint}`;
}

export default async function request(
  method: HttpVerb = HttpVerb.GET,
  endpoint: string = '',
  body = {},
) {
  const requestOptions = {
    body: method !== HttpVerb.GET ? JSON.stringify(body) : null,
    headers: defaultHeaders,
    method,
  };

  const response = await fetch(getFullEndpoint(endpoint), requestOptions);
  return response.json();
}
