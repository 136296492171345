import React, { useState } from 'react';

import { useMailchimp, Status } from '@shared/hooks/useMailchimp';
import useWindowDimensions from '@shared/hooks/useWindowDimensions';

import NavbarEmpty from '@components/NavBars/NavbarEmpty';
import HeroText from '@components/HeroText';
import ContentCentered from '@components/Content/ContentCentered';
import ShadowSeparator from '@components/ShadowSeparator';
import BulletedText from '@components/BulletedText';
import { PrimaryButton } from '@components/Buttons';
import Button from '@components/Buttons/Button';

import { ColorStyles } from '@shared/types';
import classNames from '@utils/classNames';

import Logo0 from '@assets/images/logos/logo0.png';
import Logo1 from '@assets/images/logos/logo1.png';
import Logo2 from '@assets/images/logos/logo2.png';
import Logo3 from '@assets/images/logos/logo3.png';
import Logo4 from '@assets/images/logos/logo4.png';
import Logo5 from '@assets/images/logos/logo5.png';
import Logo6 from '@assets/images/logos/logo6.png';
import Logo7 from '@assets/images/logos/logo7.png';
import Logo8 from '@assets/images/logos/logo8.svg';
import Logo9 from '@assets/images/logos/logo9.svg';
import LogoProceeds from '@assets/images/logos/logo-proceeds.png';

import BouncingCircle from './BouncingCircle';

import styles from './Landing.module.scss';

const MailTo = ({ name, message }: any) => (
  <Button
    className={classNames(
      styles.rowContactButton,
      styles.backgroundPrimary,
      styles.colorLightGray,
    )}
    onClick={() =>
      window.open(
        `mailto:${'hello@artxcode.io'}?subject=${
          encodeURIComponent(`${name} - Message`) || ''
        }&body=${encodeURIComponent(message) || ''}`,
      )
    }
  >
    submit
  </Button>
);

const Landing = () => {
  const { width, height, breakpoints } = useWindowDimensions();
  const smallSize = () => width <= breakpoints.sm;

  const [formEmail, setFormEmail] = useState('');
  const [formMailTo, setFormMailTo] = useState({
    name: '',
    email: '',
    message: '',
  });
  let formMessage = '';
  const { subscribe, status, error, value } = useMailchimp(
    'https://minervagate.us5.list-manage.com/subscribe/post?u=9e1b4458ad1c34a3b6be78df1&amp;id=0271284582',
  );

  const handleEmailSignUp = () => {
    subscribe({
      EMAIL: formEmail,
    });
  };

  switch (status) {
    case Status.loading: {
      formMessage = 'Loading...';
      break;
    }
    case Status.error: {
      formMessage = 'An error occured, please try again.';
      break;
    }
    case Status.success: {
      formMessage = 'Successfully subscribed!';
      break;
    }
    default: {
      break;
    }
  }

  return (
    <>
      {!smallSize() && (
        <div
          style={{
            position: 'absolute',
            left: '30%',
            width: '70%',
            height: 600,
          }}
        >
          <BouncingCircle circleRadius={200} width={width * 0.7} height={600} />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.heroText}>
          <h1>A Generative Art Salon and Auction</h1>
          <h1>
            Miami Art Week
            <br />
            Dec. 3–5, 2021
          </h1>

          <h1 className={styles.colorSecondary}>
            1212 Lincoln Road
            <br />
            Miami Beach, FL 33139
          </h1>
        </div>
        <div className={styles.smallScreenButton}>
          {smallSize() && (
            <BouncingCircle
              circleRadius={52}
              width={width * 0.9}
              height={height * 0.3}
              speed={50}
            />
          )}
        </div>
        <div className={classNames(styles.content, styles.artistList)}>
          <span className={styles.colorSecondary}>Featured artists</span> Aaron
          Penne, Alexis André, Ansh Kumar, Casey Reas, Dmitri Cherniak, Hideki
          Tsukamoto, ixshells, Jake Rockland, Jason Ting, Jeff Davis, Kaylla
          Torres, Matt DesLauriers, Michael Connolly, Minne Atairu, Monica
          Rizzolli, Sarah Ridgley, Snowfro, Stefano Contiero, and Tyler Hobbs.
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <p>This exhibition is proudly brought to you by</p>
          <a href="https://www.artxcode.io/">
            <img
              id="artxcode-logo"
              className={styles.imageLogo}
              alt="logo0"
              src={Logo0}
            />
          </a>
          <br />
          <p>
            through the generous support of{' '}
            <a href="https://www.artxcode.io/">Art Blocks</a> and{' '}
            <a href="https://www.moonpay.com/">MoonPay</a> <br />
          </p>
          <p>
            A special thank you to Barcardi, Upshot, 6529, Cyber, Fingerprints
            DAO, Starry Night Capital, and AC Collection
          </p>

          <div className={styles.logoRow} style={{ maxWidth: 500 }}>
            <a href="https://www.moonpay.com/">
              <img className={styles.imageLogo} alt="logo9" src={Logo9} />
            </a>
            <a href="#">
              <img className={styles.imageLogo} alt="logo8" src={Logo8} />
            </a>
          </div>

          <div className={styles.logoRow}>
            <a href="https://www.artblocks.io/">
              <img className={styles.imageLogo} alt="logo1" src={Logo1} />
            </a>
            <a href="http://bacardilimited.com/">
              <img className={styles.imageLogo} alt="logo2" src={Logo2} />
            </a>
          </div>

          <div className={styles.logoRow}>
            <a href="https://www.oncyber.io/">
              <img className={styles.imageLogo} alt="logo1" src={Logo4} />
            </a>
            <a href="http://upshot.com/">
              <img className={styles.imageLogo} alt="logo2" src={Logo5} />
            </a>
            <a href="https://www.6529.io/">
              <img className={styles.imageLogo} alt="logo1" src={Logo6} />
            </a>
          </div>
          <div className={styles.logoRow}>
            <a href="https://www.fingerprintsdao.xyz/">
              <img className={styles.imageLogo} alt="logo2" src={Logo3} />
            </a>
          </div>

          <div className={styles.content}>
            <em>Stay tuned for more information.</em>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
