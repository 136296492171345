import React, { useState, useEffect, useRef } from 'react';

import useWindowDimensions from '@shared/hooks/useWindowDimensions';

import styles from './BouncingCircle.module.scss';

interface IBouncingCircleProps {
  circleRadius: number;
  width?: number;
  height?: number;
  speed?: number;
}

const BouncingCircle = ({
  circleRadius,
  width = 1000,
  height = 500,
  speed = 100,
}: IBouncingCircleProps) => {
  const [circlePosition, setCirclePosition] = useState([
    circleRadius,
    circleRadius,
  ]);
  const timestamp = useRef(Date.now());

  const currentX = useRef(circlePosition[0]);
  const currentY = useRef(circlePosition[1]);

  const incrementX = useRef(1);
  const incrementY = useRef(1);

  const limitX = width - circleRadius;
  const limitY = height - circleRadius;

  useEffect(() => {
    const moveCircle = () => {
      const newTimestamp = Date.now();
      const deltaSeconds = (newTimestamp - timestamp.current) / 1000;

      if (deltaSeconds) {
        currentX.current += deltaSeconds * speed * incrementX.current;
        currentY.current += deltaSeconds * speed * incrementY.current;
      }

      if (currentX.current < circleRadius) {
        currentX.current = circleRadius;
        incrementX.current *= -1;
      }
      if (currentY.current < circleRadius) {
        currentY.current = circleRadius;
        incrementY.current *= -1;
      }
      if (currentX.current > limitX) {
        currentX.current = limitX;
        incrementX.current *= -1;
      }
      if (currentY.current > limitY) {
        currentY.current = limitY;
        incrementY.current *= -1;
      }

      setCirclePosition([currentX.current, currentY.current]);
      timestamp.current = newTimestamp;
    };

    const interval = setInterval(moveCircle, 10);

    return () => clearInterval(interval);
  }, [width, height, speed]);

  return (
    <div
      className={styles.buttonContainer}
      style={{
        width: circleRadius * 2,
        height: circleRadius * 2,
        top: circlePosition[1] - circleRadius,
        left: circlePosition[0] - circleRadius,
      }}
    >
      <a
        href="https://www.eventbrite.com/e/the-digital-miami-beach-2021-tickets-210923687427"
        tabIndex={0}
        className={styles.button}
      >
        Get Tickets
      </a>
    </div>
  );
};

export default BouncingCircle;
