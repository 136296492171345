import { MONTHS, TIME_FORMATS } from '@shared/constants';

export const groupDates = (array) => {
  const hashTable = {};
  const hashTableArrayValues = [] as string[];
  array.forEach((notice) => {
    const notificationDate = new Date(notice.time).toISOString().split('T')[0];
    if (!hashTable[notificationDate]) {
      hashTable[notificationDate] = [];
      hashTableArrayValues.push(notificationDate);
    }
    hashTable[notificationDate] = [
      ...Object.values(hashTable[notificationDate]),
      notice,
    ];
  });
  return { hashTable, hashTableArrayValues };
};

export function nth(d) {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
}

export function dateToYMD(date) {
  const newDate = new Date(date);
  const d = newDate.getDate();
  const y = newDate.getFullYear();
  const m = MONTHS[newDate.getMonth()];
  return `${m}, ${nth(d)}, ${y}`;
}

export const timeAgo = (date) => {
  let seconds = (+new Date() - date) / 1000;
  let token = 'ago';
  let listChoice = 1;

  if (seconds <= 1) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    listChoice = 2;
  }

  let response: number | string = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const format of TIME_FORMATS) {
    if (seconds < format[0]) {
      if (typeof format[2] === 'string') {
        response = `${format[1]}`;
      } else {
        response = `${Math.floor(seconds / +format[2])} ${format[1]} ${token}`;
      }
      break;
    }
    if (seconds === format[0]) {
      response = format[listChoice];
      break;
    }
  }
  return response;
};
