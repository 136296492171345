import React from 'react';

import ArrowLink from '@components/ArrowLink';
import BulletedText from '@components/BulletedText';
import { ColorStyles } from '@shared/types';
import Arrow from '@components/Arrow';
import ContentCentered from '@components/Content/ContentCentered';

import styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.footerContainer}>
    <ContentCentered>
      <BulletedText variant={ColorStyles.SOFT_GREEN}>
        A Generative Art Salon & Auction
      </BulletedText>
      <span>Dec. 3–5, 2021. Miami Beach, FL</span>
      <br /> <br /> <br />
      <a href="https://twitter.com/artxcode_io">
        <Arrow right /> Twitter
      </a>
      <br />
      <a href="https://instagram.com/artxcode.io">
        <Arrow right /> Instagram
      </a>
      <br />
      <br />
      <BulletedText variant={ColorStyles.SECONDARY}>
        {/* <a href="/about">About, </a>
        <a href="/TOS">Terms of service, </a>
        <a href="/help">Help, </a>
        <a href="/privacy">Privacy.</a> */}
        <a href="https://www.eventbrite.com/e/the-digital-miami-beach-2021-tickets-210923687427">
          Tickets,{' '}
        </a>
        <a href="mailto:hello@artxcode.io">Contact us.</a>
      </BulletedText>
    </ContentCentered>
  </div>
);

export default Footer;
