import { createAction } from 'redux-actions';
import { AuctionAPI } from '@api';

export const getAuctionItems = createAction(
  'auction/GET_AUCTION_ITEMS',
  async (page, filter) => {
    const response = await AuctionAPI.getAuctionItems(page, filter);
    return response;
  },
);

export const clearAuctionItems = createAction('auction/CLEAR_AUCTION_ITEMS');

export const getSingleAuctionItem = createAction(
  'auction/GET_SINGLE_AUCTION_ITEM',
  async (id) => {
    const response = await AuctionAPI.getSingleAuctionItem(id);
    return response;
  },
);

export const getSimilarAuctionItems = createAction(
  'auction/GET_SIMILAR_AUCTION_ITEMS',
  async (id) => {
    const response = await AuctionAPI.getSimilarAuctionItems(id);
    return response;
  },
);
